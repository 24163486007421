import React, { useContext, useState } from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Container from "../components/container";
import { useIntl } from "react-intl";
import { Box, Image, Stack, Text } from "grommet";
import { Heading } from "../components/typo";
import { AppContext } from "../context/AppContext.js";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import { getProfile } from "../queries.js";
import { API, Auth } from "aws-amplify";

const Passport = ({ data, pageContext }) => {
  const intl = useIntl();
  const locale = pageContext.locale;
  const originalPath = pageContext.originalPath;
  const { appState } = useContext(AppContext);
  const [visits, setVisits] = useState([]);
  const boutiques = data.allFile.nodes.map(
    (boutique) => boutique.childMdx.frontmatter
  );
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return API.graphql({
          query: getProfile,
        });
      })
      .then((response) => {
        let visits = response.data.getProfile?.visits || null;

        setVisits(visits === null ? [] : JSON.parse(visits));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        const link =
          locale === "fr" ? "/registration/" : `/${locale}/registration/`;
        navigate(link);
      });
  }, []);

  if (loading === true) {
    return null;
  }

  return (
    <Layout locale={locale} originalPath={originalPath}>
      <Seo title={intl.formatMessage({ id: "page-digital-passport" })} />
      <Container>
        <Heading level="1" textAlign="center">
          {intl.formatMessage({ id: "page-digital-passport" })}
        </Heading>
        <Text
          margin={{ bottom: "50px" }}
          as="p"
          textAlign="center"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "passport-heading" }),
          }}
        />
        <Box width={{ max: "800px" }} margin="0 auto">
          <Stack>
            <Box>
              <Image src="/passport/passport-bkg.png"></Image>
            </Box>
            {boutiques.map((boutique) => {
              if (visits.indexOf(boutique.name) === -1) {
                return null;
              }

              return (
                <Box key={boutique.slug}>
                  <Image
                    src={
                      "/passport/" +
                      boutique.slug.replace(/\//g, "") +
                      "-validation.png"
                    }
                  ></Image>
                </Box>
              );
            })}
          </Stack>
        </Box>
        {appState.loggedin === true && (
          <Box width="200px" margin={{ horizontal: "auto", vertical: "large" }}>
            <AmplifySignOut />
          </Box>
        )}
      </Container>
    </Layout>
  );
};

export default Passport;

export const query = graphql`
  query ($locale: String!) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "boutiques" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            slug
            name
          }
        }
      }
    }
  }
`;
